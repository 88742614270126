.linescolors__contactbox {
    padding: 10px;
    border: 1px solid #eaecee;
    margin-bottom: 20px;
    border-radius: 5px;
}

.linescolors__contactbox p {
    color: #34495e !important;
    margin-left: 14px !important;
    margin-bottom: 0px !important;
}