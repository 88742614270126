.linescolors__packs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.linescolors__packs-container img {
    width: 230px;
    height: 200px;
}

.linescolors__packs-container__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 18px;
}

.linescolors__packs-container__content h1 {
    font-family: var(--font-family);
    font-size: 20px !important;
    color: #2980b9;
    margin-bottom: 8px;
}

.linescolors__packs-container__content p {
    font-family: var(--font-family);
    color: #85929e;
    margin-top: 18px;
}

.linescolors__packs-content__gradient_border_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, slateblue, coral) border-box;
    border: 2px solid transparent;
    border-radius: 50px;
    margin-top: 18px;
}

.linescolors__packs-content__gradient_border_btn p {
    margin: 0;
}