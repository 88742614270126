@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(to right, #fc5c7d, #6a82fb);
  
  --color-bg: #FAF9F6;
  --color-footer : #031B34;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}