.linescolors__contact-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.linescolors__contact-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f2f4f4   ;
    justify-content: space-around;
    align-items: center;
    height: 450px;
    border-radius: 10px;
}

.linescolors__contact-form input {
    width: 100%;
    height: 40px;
    color:  #85929e;
    padding: 0px 20px;
    border: none;
}

.linescolors__contact-form textarea {
    width: 100%;
    height: 120px;
    padding: 10px 20px;
    color:  #85929e;
    border: none;
}

.linescolors__contact-form button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: #2980b9;
    color: white;
}

.linescolors__contact-container__content h4 {
    font-family: var(--font-family);
    margin-bottom: 20px;
}

.linescolors__contact-container__content h1 {
    font-family: var(--font-family);
}

.linescolors__contact-container__content p {
    font-family: var(--font-family);
    color: #aeb6bf;
}