.linescolors__about-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.linescolors__about-container__img img{
    width: 450px;
    height: 380px;
}

@media screen and (max-width: 1050px) {
    .linescolors__about-container__img img{
        width: auto;
        height: 400px;
    }
}