.linescolors__privacy-container {
    padding-top: 40px;
}

.linescolors__privacy-container h1 {
    font-family: var(--font-family);
    color: #2980b9;
    margin-bottom: 20px;
}

.linescolors__privacy-container p {
    font-family: var(--font-family);
    line-height: 30px;
    margin-bottom: 20px;
}

.linescolors__privacy-container h4 {
    font-family: var(--font-family);
}

.linescolors__privacy-back {
    margin-bottom: 24px;
    cursor: pointer;
}