.linescolors__description-container h1{
    font-family: var(--font-family);
    font-size: 30px;
}

.linescolors__description-container p {
    font-family: var(--font-family);
    color: #85929e;
}

.linescolors__description-container__items h1 {
    font-family: var(--font-family);
    font-size: 20px;
}

@media screen and (max-width: 1050px) {
    .linescolors__description-container h1{
        font-size: 25px;
    }

    .linescolors__description-container__items h1 {
        font-size: 15px;
    }
}