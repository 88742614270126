.linescolors__footer-container {
    padding: 34px 0px;
}

.linescolors__footer-content h3 {
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 20px;
}

.linescolors__footer-content h1 {
    font-family: var(--font-family);
    color: #fff;
    line-height: 35px;
    margin-bottom: 14px;
}

.linescolors__footer-content p {
    font-family: var(--font-family);
    color: #ecf0f1;
    margin-bottom: 12px;
}

.linescolors__footer-content h5 {
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 20px;
}