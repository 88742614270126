.linescolors__aboutposters-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.linescolors__aboutposters-container__img img{
    width: 400px;
    height: 400px;
}

.linescolors__aboutposters-container__img {
    display: flex;
    align-items: center;
    justify-content: center;
}