.linescolors__navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem;
    background: transparent;
}

.linescolors__navbar-container__icon p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
}

.linescolors__navbar-container__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.linescolors__navbar-container__links p {
    font-family: var(--font-family);
    margin-right: 18px;
}

.linescolors__navbar-container__togglemenu {
    display: none;
}

.linescolors__navbar-container__togglemenu__menu {
    display: flex;
    flex-direction: column;
    padding: 18px 16px;
    justify-content: flex-end;
    align-items: flex-end;
    position: fixed;
    top: 70px;
    right: 40px;
    min-width: 150px;
}

.linescolors__navbar-container__togglemenu__menu p {
    font-family: var(--font-family);
    margin-bottom: 16px;
}

@media screen and (max-width: 750px) {
    .linescolors__navbar-container__togglemenu {
        display: flex;
    }

    .linescolors__navbar-container__links {
        display: none;
    }
}