
.linescolors__header-container__content {
    padding: 2rem 0rem;
}

.linescolors__header-container__content h1 {
    font-family: var(--font-family);
    line-height: 60px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.linescolors__header-container__content p {
    font-family: var(--font-family);
    line-height: 20px;
    font-size: 20px;
    line-height: 30px;
}

.linescolors__header-container__img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.linescolors__header-container__gradient__btn {
    background: #fc5c7d;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fc5c7d, #6a82fb);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 8px 8px;
    max-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 20px 0px;
}

.linescolors__header-container__gradient__btn p {
    color: white;
}
