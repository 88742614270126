
.linescolors__service-container {
    max-width: 360px;
}

.linescolors__service-container h2 {
    font-family: var(--font-family);
    font-size: 24px;
    margin-top: 14px;
    color: #2980b9;
    margin-bottom: 14px;
}

.linescolors__service-container p {
    font-family: var(--font-family);
    color: #85929e;
}

.linescolors__service-container img{
    border-radius: 10px;
    width: 350px;
}