* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

.highlight {
    color: #2980b9;
}

.gradient_box {
    background: #FC5C7D;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #6A82FB, #FC5C7D);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient_line {
    background: #FC5C7D;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #6A82FB, #FC5C7D);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 3px;
    width: 80px;
}

.header_gradient_bg{

     /* ff 3.6+ */
     background:-moz-radial-gradient(circle at 77% 54%, rgba(252, 92, 125, 0.34) 2%, rgba(250, 249, 246, 1) 33%);

     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(circle at 77% 54%, rgba(252, 92, 125, 0.34) 2%, rgba(250, 249, 246, 1) 33%);
 
     /* opera 11.10+ */
     background:-o-radial-gradient(circle at 77% 54%, rgba(252, 92, 125, 0.34) 2%, rgba(250, 249, 246, 1) 33%);
 
     /* ie 10+ */
     background:-ms-radial-gradient(circle at 77% 54%, rgba(252, 92, 125, 0.34) 2%, rgba(250, 249, 246, 1) 33%);
 
     /* global 92%+ browsers support */
     background:radial-gradient(circle at 77% 54%, rgba(252, 92, 125, 0.34) 2%, rgba(250, 249, 246, 1) 33%);

}

.doings_bg{
    background: #2980b9;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text; 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact_bg {
    margin-top: 20px;
}

.service_bg {
    margin-top: 20px;
}

.footer_bg {
    margin-top: 20px;
    background: #2980b9;
}

